import React from "react";
import { graphql } from "gatsby";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Block from "../components/_global/editorial/Block";
import Layout from "../components/_global/layout/Layout";
import localize from "../components/_global/localization/localize";
import Spacer from "../components/_global/layout/Spacer";
import Conditional from "../components/_global/layout/Conditional";
import TagList from "../components/_global/lists/TagList";
import ItemList from "../components/_global/lists/ItemList";

function compare(a, b) {
	if (a.title < b.title) {
		return -1;
	}
	if (a.title > b.title) {
		return 1;
	}
	return 0;
}

const Learn = ({ data, ...props }) => {
	const sanityData = data.sanityLearn;
	const contentData = data.allSanityLearnFile.nodes;
	const genData = data.sanityGeneralComponent;

	let catFiles = [];
	for (let i = 0; i < contentData.length; i++) {
		if (!catFiles.find(item => item._id === contentData[i].category._id)) {
			// create the category array
			catFiles.push({
				_id: contentData[i].category._id,
				title: contentData[i].category.title,
				items: []
			});
			catFiles.find(item => item._id === contentData[i].category._id).items.push(contentData[i]);
		} else {
			catFiles.find(item => item._id === contentData[i].category._id).items.push(contentData[i]);
		}
	}
	catFiles.sort(compare);

	return (
		<Layout title={sanityData.common.title}>
			<PageHeader title={sanityData.common.title} />

			<Section m="small">
				<Block data={sanityData.text} />
				<Spacer s="medium" />
			</Section>

			<Section m="small" style={{ position: "sticky", top: "133px", backgroundColor: "white", zIndex: 1 }}>
				<TagList data={catFiles} />
			</Section>

			<Conditional desktop>
				<Spacer s="large" />
			</Conditional>

			<Conditional mobile>
				<Spacer s="medium" />
			</Conditional>

			<ItemList data={catFiles} more={genData.learnMore} baseLink="learn" />
		</Layout>
	);
};

export default localize(Learn);

export const query = graphql`
    {
        sanityLearn {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            text {
                ...RegularBlockFragment
            }
        }
        allSanityLearnFile(sort: {fields: title___fr, order: ASC}) {
            nodes {
                ...LearnFileFragment
            }
        }
        sanityGeneralComponent {
            learnMore {
                _type
                fr
            }
            goFurther {
                _type
                fr
            }
        }
    }
`;
